<template>
  <div :style="'height: '+height+'px; width: 100%'">
    <l-map
        v-if="showMap"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        class="rounded-xl"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />
      <l-marker v-for="marker of markers" :lat-lng="marker.latLng" :key="marker">
        <l-popup :options="{ permanent: true, interactive: true }">

          <div >
            <div @click="innerClick(marker.id, marker)" v-if="marker.values">
              <h3>
                {{ marker.values.name }}
              </h3>
              <p>
                <b>
                  <span v-if="marker.values.priceClass === 1">€</span>
                  <span v-if="marker.values.priceClass === 2">€€</span>
                  <span v-if="marker.values.priceClass === 3">€€€</span>
                </b>
              </p>
              <p v-if="marker.values.category">
                <b>{{ marker.values.category.name }}</b>
              </p>
              <p>
              <span v-for="stil of marker.values.stil" :key="stil.value">
                <b>{{ stil.name }}</b>
              </span>
              </p>
              <p v-if="marker.values.maxPersonen">
                Max. <b>{{ marker.values.maxPersonen }}</b> Personen
              </p>
              <p v-if="marker.values.groesseInnen || marker.values.groesseAussen">
                Außen: <b>{{ marker.values.groesseInnen }}</b><br>
                Innen: <b>{{ marker.values.groesseAussen }}</b>
              </p>
            </div>
            <div @click="innerClick(marker.id, marker)" v-else>
              <h3>
                {{ marker.name }}
              </h3>
              <p>
                <b>
                  <span v-if="marker.priceClass === 1">€</span>
                  <span v-if="marker.priceClass === 2">€€</span>
                  <span v-if="marker.priceClass === 3">€€€</span>
                </b>
              </p>
              <p v-if="marker.category">
                <b>{{ marker.category.name }}</b>
              </p>
              <p>
                <span v-for="stil of marker.stil" :key="stil.value">
                <b>{{ stil.name }}</b>
              </span>
              </p>


              <p v-if="marker.maxPersonen">
                Max. <b>{{ marker.maxPersonen }}</b> Personen
              </p>
              <p v-if="marker.groesseInnen || marker.groesseAussen">
                Außen: <b>{{ marker.groesseInnen }}</b><br>
                Innen: <b>{{ marker.groesseAussen }}</b>
              </p>
            </div>
          </div>

        </l-popup>
      </l-marker>

      <l-marker v-for="marker of mainMarkers" :lat-lng="marker.latLng" :key="marker.id" >
        <l-popup :options="{ permanent: true, interactive: true, }">
          <div >
            <div @click="innerClick(marker.id, marker)" v-if="marker.values">
              <h3>
                {{ marker.values.name }}
              </h3>
              <p>
                <b>
                  <span v-if="marker.values.priceClass === 1">€</span>
                  <span v-if="marker.values.priceClass === 2">€€</span>
                  <span v-if="marker.values.priceClass === 3">€€€</span>
                </b>
              </p>
              <p v-if="marker.values.category">
                <b>{{ marker.values.category.name }}</b>
              </p>
              <p>
              <span v-for="stil of marker.values.stil" :key="stil.value">
                <b>{{ stil.name }}</b>
              </span>
              </p>
              <p v-if="marker.values.maxPersonen">
                Max. <b>{{ marker.values.maxPersonen }}</b> Personen
              </p>
              <p v-if="marker.values.groesseInnen || marker.values.groesseAussen">
                Außen: <b>{{ marker.values.groesseInnen }}</b><br>
                Innen: <b>{{ marker.values.groesseAussen }}</b>
              </p>
            </div>
            <div @click="innerClick(marker.id, marker)" v-else>
              <h3>
                {{ marker.name }}
              </h3>
              <p>
                <b>
                  <span v-if="marker.priceClass === 1">€</span>
                  <span v-if="marker.priceClass === 2">€€</span>
                  <span v-if="marker.priceClass === 3">€€€</span>
                </b>
              </p>
              <p v-if="marker.category">
                <b>{{ marker.category.name }}</b>
              </p>
              <p>
                <span v-for="stil of marker.stil" :key="stil.value">
                <b>{{ stil.name }}</b>
              </span>
              </p>


              <p v-if="marker.maxPersonen">
                Max. <b>{{ marker.maxPersonen }}</b> Personen
              </p>
              <p v-if="marker.groesseInnen || marker.groesseAussen">
                Außen: <b>{{ marker.groesseInnen }}</b><br>
                Innen: <b>{{ marker.groesseAussen }}</b>
              </p>
            </div>
          </div>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import {latLng, Icon} from "leaflet";
import {LMap, LTileLayer, LMarker, LPopup} from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/marker.png'),
  iconUrl: require('@/assets/marker.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
  name: "Example",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,

  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: this.zoom,
      currentCenter: this.center,
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true
    };
  },
  props: {
    markers: {
      type: Array,
      default() {
        return []
      }
    },
    mainMarkers: {
      type: Array,
      default() {
        return []
      }
    },
    height:{
      type: [String, Number],
      default() {
        return 500
      }
    },
    center:{
      type: Object,
      default() {
        return latLng(51.1642292, 10.4541194)
      }
    },
    zoom:{
      type: Number,
      default() {
        return 5
      }
    }
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick(id, marker) {
      if(marker.category || marker.values.category){
        this.$router.push('/dienstleister/dienstleister/'+id);
      }else{
        this.$router.push('/locations/location/'+id);

      }
    }
  }
};
</script>
